import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import MarkdownIt from 'markdown-it';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { ClipLoader } from 'react-spinners';
import './App.css';

const libraries = ['places'];

function App() {
  const [address, setAddress] = useState('');
  const [inputType, setInputType] = useState('bill');
  const [inputValue, setInputValue] = useState('');
  const [isManualInput, setIsManualInput] = useState(false);
  const [manualSystemSize, setManualSystemSize] = useState('');
  const incrementingKw = 0.405;
  const minSystemSizeKw = 4.05;
  const maxSystemSizeKw = 24.3;
  const [manualPowerwallCount, setManualPowerwallCount] = useState('');
  const maxPowerwallCount = 10;
  const [results, setResults] = useState(null);
  const [analysis, setAnalysis] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [error, setError] = useState('');
  const [analysisHtml, setAnalysisHtml] = useState('');
  const resultsRef = useRef(null);
  const mdParser = useRef(new MarkdownIt());
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState('');
  const [autocomplete, setAutocomplete] = useState(null);
  const [monthlyConsumptionVsProduction, setMonthlyConsumptionVsProduction] = useState([]);
  const [isAnalysisLoading, setIsAnalysisLoading] = useState(false);
  const [showVenmoModal, setShowVenmoModal] = useState(false);
  const [isAnalysisComplete, setIsAnalysisComplete] = useState(true);

  const analysisRef = useRef(null);
  const socket = useRef(null);
  const [roomId, setRoomId] = useState(null);
  const analysisCompleteTimer = useRef(null);

  useEffect(() => {
    socket.current = io('https://server.solarsim.xyz', {
      transports: ['websocket'],
      cors: {
        origin: 'https://solarsim.xyz',
        methods: ['GET', 'POST']
      }
    });

    socket.current.on('connect', () => {
      console.log('Socket.IO Connected');
      setIsSocketConnected(true);
    });

    socket.current.on('connect_error', (error) => {
      console.error('Socket.IO Connection Error:', error);
      setError('Failed to connect to the server. Please try again later.');
    });

    socket.current.on('message', (message) => {
      switch (message.type) {
        case 'results':
          setResults(message.data);
          setLoading(false);
          if (message.data.monthly_consumption_vs_production) {
            setMonthlyConsumptionVsProduction(message.data.monthly_consumption_vs_production);
          }
          break;
        case 'analysis':
          setAnalysis(prev => {
            const newAnalysis = prev + message.data;
            const html = mdParser.current.render(newAnalysis);
            setAnalysisHtml(html);
            return newAnalysis;
          });
          // Start a timer to set isAnalysisComplete to true
          clearTimeout(analysisCompleteTimer.current);
          analysisCompleteTimer.current = setTimeout(() => {
            setIsAnalysisComplete(true);
            setIsCalculating(false);
          }, 5000); // 5 seconds timeout
          break;
        case 'error':
          setError(message.data);
          setLoading(false);
          setIsCalculating(false);
          setIsAnalysisComplete(true);
          break;
        default:
          console.warn('Unknown message type:', message.type);
      }
    });

    socket.current.on('disconnect', () => {
      console.log('Socket.IO Disconnected');
      setIsSocketConnected(false);
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (roomId) {
      socket.current.emit('join', roomId);
      console.log(`Joined room: ${roomId}`);
    }
  }, [roomId]);

  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (analysisRef.current) {
      analysisRef.current.scrollTop = analysisRef.current.scrollHeight;
    }
  }, [analysis]);

  useEffect(() => {
    if (analysis) {
      const html = mdParser.current.render(analysis);
      setAnalysisHtml(html);
      setIsAnalysisLoading(false); // Set to false when we start receiving analysis
    }
  }, [analysis]);

  useEffect(() => {
    fetch('/api/google-maps-key')
      .then(response => response.json())
      .then(data => {
        setGoogleMapsApiKey(data.key);
      })
      .catch(error => console.error('Error fetching Google Maps API key:', error));
  }, []);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      setAddress(place.formatted_address);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsAnalysisLoading(true);
    setIsCalculating(true);
    setIsAnalysisComplete(false); // Set to false when starting a new calculation
    setError('');
    setResults(null);
    setAnalysis('');
    setAnalysisHtml('');
    setMonthlyConsumptionVsProduction([]);

    try {
      await ensureSocketConnection();

      const response = await fetch('https://server.solarsim.xyz/configure_and_analyze', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address,
          input_type: inputType,
          input_value: parseFloat(inputValue),
          is_manual_input: isManualInput,
          manual_system_size: isManualInput ? parseFloat(manualSystemSize) || null : null,
          manual_powerwall_count: isManualInput ? parseInt(manualPowerwallCount) || null : null,
          sid: socket.current.id
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // Handle the response data if needed
    } catch (err) {
      setError('An error occurred. Please try again.');
      console.error(err);
      setIsAnalysisLoading(false);
      setIsCalculating(false);
      setIsAnalysisComplete(true);
    } finally {
      setLoading(false);
    }
  };

  const formatNumber = (num) => {
    return num ? num.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 'N/A';
  };

  const formatCurrency = (num) => {
    return num ? `$${num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'N/A';
  };

  const ensureSocketConnection = () => {
    return new Promise((resolve, reject) => {
      if (isSocketConnected) {
        resolve();
      } else {
        socket.current.connect();
        socket.current.once('connect', () => {
          setIsSocketConnected(true);
          resolve();
        });
        socket.current.once('connect_error', (error) => {
          reject(error);
        });
        // Set a timeout in case the connection attempt takes too long
        setTimeout(() => {
          reject(new Error('Connection timeout'));
        }, 5000); // 5 seconds timeout
      }
    });
  };

  const VenmoModal = ({ onClose }) => {
    const venmoUsername = 'fnfirst';
    const baseVenmoUrl = `https://venmo.com/${venmoUsername}?txn=pay&audience=private`;
    const [customTipAmount, setCustomTipAmount] = useState('1000');

    const handleTip = (amount) => {
      const message = "Thanks for SolarSim. It really helped me with my Tesla Solar decision!";
      const encodedMessage = encodeURIComponent(message);
      const venmoUrl = `${baseVenmoUrl}&amount=${amount}&note=${encodedMessage}`;
      window.open(venmoUrl, '_blank');
    };

    const handleCustomTipChange = (e) => {
      // Remove non-digit characters and leading zeros
      const value = e.target.value.replace(/\D/g, '').replace(/^0+/, '');
      setCustomTipAmount(value);
    };

    return (
      <div className="venmo-modal-overlay">
        <div className="venmo-modal">
          <h2>Tip with Venmo</h2>
          <p>Thank you for considering a tip! Choose an amount or enter your own:</p>
          <div className="tip-buttons">
            <button onClick={() => handleTip(5)} className="tip-amount-button">$5.00</button>
            <button onClick={() => handleTip(10)} className="tip-amount-button">$10.00</button>
            <div className="custom-tip-input">
              <span className="dollar-sign">$</span>
              <input
                type="text"
                value={customTipAmount}
                onChange={handleCustomTipChange}
                placeholder="Custom amount"
              />
              <button onClick={() => handleTip(customTipAmount)} className="tip-amount-button">Tip</button>
            </div>
          </div>
          <button onClick={onClose} className="close-button">Close</button>
        </div>
      </div>
    );
  };

  const handleManualSystemSizeChange = (e) => {
    const value = e.target.value;
    if (value === '' || isNaN(parseFloat(value))) {
      setManualSystemSize('');
    } else {
      // Allow any input without rounding
      setManualSystemSize(value);
    }
  };

  const handleManualSystemSizeBlur = () => {
    const value = parseFloat(manualSystemSize);
    if (!isNaN(value)) {
      const roundedValue = Math.floor(value / incrementingKw) * incrementingKw;
      const clampedValue = Math.max(minSystemSizeKw, Math.min(maxSystemSizeKw, roundedValue));
      setManualSystemSize(clampedValue.toFixed(3));
    } else {
      setManualSystemSize('');
    }
  };

  const handleManualSystemSizeKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      const currentValue = parseFloat(manualSystemSize) || 0;
      const newValue = e.key === 'ArrowUp' 
        ? currentValue + incrementingKw 
        : currentValue - incrementingKw;
      const clampedValue = Math.max(minSystemSizeKw, Math.min(maxSystemSizeKw, newValue));
      setManualSystemSize(clampedValue.toFixed(3));
    }
  };

  const handleManualPowerwallCountChange = (e) => {
    const value = e.target.value;
    if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= maxPowerwallCount)) {
      setManualPowerwallCount(value);
    }
  };

  const maxMonthlyBill = 5000;

  const formatNumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInputValueChange = (e) => {
    const rawValue = e.target.value.replace(/[^\d]/g, ''); // Remove non-digit characters
    const numericValue = parseInt(rawValue, 10);
    
    if (rawValue === '' || (numericValue >= 0 && numericValue <= maxMonthlyBill)) {
      setInputValue(rawValue === '' ? '' : numericValue.toString());
    }
  };

  const displayValue = inputValue === '' ? '' : formatNumberWithCommas(inputValue);

  useEffect(() => {
    if (roomId) {
      socket.current.emit('join', roomId);
    }
  }, [roomId]);

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <img src="/logo192.png" alt="SolarSim Logo" className="app-logo" />
          <div className="header-text">
            <h1 className="header-title">SolarSim</h1>
            <h2 className="header-subtitle">Get a Second Opinion on Tesla Solar</h2>
          </div>
        </div>
        <div className="tip-section">
          <span>Service is free, but Tips are appreciated!</span>
          <button onClick={() => setShowVenmoModal(true)} className="tip-button">
            Tip
          </button>
        </div>
      </header>
      <main className="App-main">
        {googleMapsApiKey && (
          <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
            <form onSubmit={handleSubmit} className="config-form">
              <div className="input-group">
                <div className="input-controls">
                  <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                  >
                    <input
                      type="text"
                      placeholder="Enter address"
                      required
                      className="address-input"
                    />
                  </Autocomplete>
                  <select
                    value={inputType}
                    onChange={(e) => setInputType(e.target.value)}
                    className="input-select"
                  >
                    <option value="bill">Monthly Bill</option>
                    <option value="kwh">Monthly kWh</option>
                  </select>
                  {inputType === 'bill' ? (
                    <div className="input-with-prefix">
                      <span className="input-prefix">$</span>
                      <input
                        type="text"
                        value={displayValue}
                        onChange={handleInputValueChange}
                        placeholder="Enter monthly bill amount"
                        required
                        className="input-number"
                        inputMode="numeric"
                        pattern="[0-9,]*"
                      />
                    </div>
                  ) : (
                    <input
                      type="number"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      placeholder="Enter monthly kWh"
                      required
                      className="input-number"
                    />
                  )}
                  <label className="manual-input-checkbox">
                    <input
                      type="checkbox"
                      checked={isManualInput}
                      onChange={(e) => setIsManualInput(e.target.checked)}
                    />
                    Manual Size
                  </label>
                  {isManualInput && (
                    <>
                      <input
                        type="number"
                        value={manualSystemSize}
                        onChange={handleManualSystemSizeChange}
                        onBlur={handleManualSystemSizeBlur}
                        onKeyDown={handleManualSystemSizeKeyDown}
                        placeholder="System Size (kW)"
                        required
                        className="input-number"
                        min={minSystemSizeKw}
                        max={maxSystemSizeKw}
                        step="any"
                      />
                      <input
                        type="number"
                        value={manualPowerwallCount}
                        onChange={handleManualPowerwallCountChange}
                        placeholder="Powerwall Count"
                        required
                        className="input-number"
                        min="0"
                        max={maxPowerwallCount}
                        step="1"
                      />
                    </>
                  )}
                      <button 
                        type="submit" 
                        disabled={loading || isCalculating || !isAnalysisComplete} 
                        className={`submit-button ${isCalculating || !isAnalysisComplete ? 'calculating' : ''}`}
                      >
                        {isCalculating ? 'Calculating...' : 
                         !isAnalysisComplete ? 'Analyzing...' : 
                         (loading ? (isSocketConnected ? 'Preparing...' : 'Connecting...') : 'Calculate')}
                      </button>
                </div>
              </div>
            </form>
          </LoadScript>
        )}

        {error && <p className="error">{error}</p>}

        <div className="results-container">
          <div className="analysis-column">
            {isAnalysisLoading ? (
              <div className="spinner-container">
                <ClipLoader color="#36D7B7" loading={isAnalysisLoading} size={50} />
                <p>Generating AI Analysis...</p>
              </div>
            ) : analysisHtml ? (
              <div className="analysis">
                <h2>AI Analysis of System Simulation</h2>
                <div 
                  ref={analysisRef} 
                  className="markdown-content"
                  dangerouslySetInnerHTML={{ __html: analysisHtml }}
                />
              </div>
            ) : null}
          </div>

          <div className="summary-column" ref={resultsRef}>
            {results && (
              <div className="results">
                <h2>Results</h2>
                <div className="summary-grid">
                  <div className="summary-item">
                    <h3>System and Production</h3>
                    <p><strong>Address:</strong> {address}</p>
                    <p><strong>Annual Consumption (User Inputted):</strong> {formatNumber(results.summary.estimated_annual_consumption)} kWh</p>
                    <p><strong>Tesla's Recommended System Size:</strong> {formatNumber(results.summary.recommended_system_size)} kW</p>
                    <p><strong>Tesla's Recommended Powerwall Count:</strong> {results.summary.powerwall_count}</p>
                    <p><strong>Tesla's Estimated Annual Production:</strong> {formatNumber(results.summary.estimated_annual_production)} kWh</p>
                    <p><strong>Solar Offset (Comparing Tesla's Production Estimate to User Inputted Annual Consumption):</strong> {formatNumber(results.summary.solar_offset)}%</p>
                    <p><strong>PVWatts Annual Production (with Soiling):</strong> {formatNumber(results.summary.pvwatts_annual_production_tesla)} kWh</p>
                    <p><strong>PVWatts Annual Production (Highest Potential):</strong> {formatNumber(results.summary.pvwatts_annual_production_higher_end)} kWh</p>
                    <p><strong>Annual Difference:</strong> {formatNumber(results.summary.annual_difference)} kWh</p>
                    <p><strong>Annual Percentage Difference:</strong> {formatNumber(results.summary.annual_percentage_difference)}%</p>
                  </div>
                  <div className="summary-item">
                    <h3>Simulation Results</h3>
                    <h4>Annual Data</h4>
                    <p><strong>Consumption:</strong> {formatNumber(results.simulation.total_consumption)} kWh</p>
                    <p><strong>Production:</strong> {formatNumber(results.simulation.total_production)} kWh</p>
                    <p><strong>Grid Consumption:</strong> {formatNumber(results.simulation.grid_consumption)} kWh</p>
                    <p><strong>Overproduction:</strong> {formatNumber(results.simulation.grid_feedin)} kWh</p>
                    <p><strong>Self-consumption Ratio:</strong> {formatNumber(results.simulation.self_consumption)}%</p>
                    <p><strong>Grid Independence:</strong> {formatNumber(results.simulation.grid_independence)}%</p>
                    <h4>Daily Data</h4>
                    <p><strong>Days with No Grid Consumption:</strong> {results.simulation.days_no_grid}</p>
                    <p><strong>Days with Some Grid Consumption:</strong> {results.simulation.days_with_grid}</p>
                    <p><strong>Days of Grid Independence:</strong> {formatNumber(results.simulation.days_of_grid_independence)}%</p>
                    <h4>Hourly Data</h4>
                    <p><strong>Hours with No Grid Consumption:</strong> {results.simulation.hours_no_grid}</p>
                    <p><strong>Hours with Some Grid Consumption:</strong> {results.simulation.hours_with_grid}</p>
                    <p><strong>Hours of Grid Independence:</strong> {formatNumber(results.simulation.hours_of_grid_independence)}%</p>
                  </div>
                  <div className="summary-item">
                    <h3>Tesla's Cost Estimate</h3>
                    <p><strong>Solar Panels ({results.summary.recommended_system_size} kW):</strong> {formatCurrency(results.summary.cost_summary.solar_cost)}</p>
                    <p><strong>Powerwall ({results.summary.powerwall_count}):</strong> {formatCurrency(results.summary.cost_summary.powerwall_cost)}</p>
                    <p><strong>Solar & Powerwall Discount:</strong> {formatCurrency(results.summary.cost_summary.powerwall_discount)}</p>
                    <p><strong>System Size Discount:</strong> {formatCurrency(results.summary.cost_summary.solar_discount)}</p>
                    <p><strong>Total Cash Price:</strong> {formatCurrency(results.summary.cost_summary.total_cost)}</p>
                    (Excludes Taxes & Incentives)
                  </div>
                </div>

                <h3>Monthly Output</h3>
                <div className="table-container">
                  <table className="monthly-output">
                    <thead>
                      <tr>
                        <th>Month</th>
                        <th>Tesla Estimate (kWh)</th>
                        <th>PVWatts Estimate (kWh)</th>
                        <th>Difference (kWh)</th>
                        <th>Difference (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {results.monthly_output.map((month, index) => (
                        <tr key={index}>
                          <td>{month.Month}</td>
                          <td>{formatNumber(month['Tesla Estimate (kWh)'])}</td>
                          <td>{formatNumber(month['PVWatts Estimate (kWh)'])}</td>
                          <td>{formatNumber(month['Difference (kWh)'])}</td>
                          <td>{formatNumber(month['Difference (%)'])}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <h3>Monthly Consumption vs. Production</h3>
                <div className="table-container">
                  <table className="monthly-output">
                    <thead>
                      <tr>
                        <th>Month</th>
                        <th>Consumption (kWh)</th>
                        <th>Production Estimate (kWh)</th>
                        <th>Difference (kWh)</th>
                        <th>Difference (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {monthlyConsumptionVsProduction.map((month, index) => (
                        <tr key={index}>
                          <td>{month.Month}</td>
                          <td>{formatNumber(month['Consumption (kWh)'])}</td>
                          <td>{formatNumber(month['PVWatts Estimate (kWh)'])}</td>
                          <td>{formatNumber(month['Difference (kWh)'])}</td>
                          <td>{formatNumber(month['Difference (%)'])}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      {showVenmoModal && <VenmoModal onClose={() => setShowVenmoModal(false)} />}
    </div>
  );
}

export default App;